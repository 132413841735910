import React, { useState, useRef } from "react";
import { baseURL } from "../../api";
import {
  Combobox,
  Option,
  useId,
  makeStyles,
  shorthands,
  Image,
  Body1Strong,
} from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";
import BackgroundDialog from "../BackgroundDialog/BackgroundDialog";
import AvatarDialog from "../AvatarDialog/AvatarDialog";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",

    // fontSize:"2.5rem",
  },
  root: {
    // Stack the label above the field with a gap
    display: "flex",
    flexDirection: "column",
    // gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    // maxWidth: "400px",
    width: "100%",
  },
});

interface ValueProps {
  headingValue: string;
  optionValue: any[];
  // show3Image:boolean
  handleAvatarValue: any;
  type: "avatar" | "background";
  // setValue:any
}

const DROPDOWNWITHIMAGECONTAINER: React.FC<ValueProps> = ({
  headingValue,
  optionValue,
  handleAvatarValue,
  type,
}) => {
  const styles = useStyles();
  const comboId = useId("combo-default");
  const [imageSrc, setImageSrc] = useState<string>("");
  const [showImg, setShowImage] = useState<boolean>(false);
  const [showUploadFile, setShowUploadFile] = useState<boolean>(false);

  const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    console.log(data.optionText);

    var findValue = optionValue.find(
      (value) => value.image_name == data.optionText
    );
    //  {
    // debugger;
    if (findValue.image_name != "Choose Another") {
      // debugger
      if (showUploadFile) {
        setShowUploadFile(false);
        setShowImage(true);
      }
      setImageSrc(findValue.imgPath);
      setShowImage(true);
      // setValue('avatar_image', data ? data.optionText : '')
      handleAvatarValue(findValue.imgPath);
    } else {
      setShowUploadFile(true);
    }
    // })
  };
  const onImageSelect = (image_name: string) => {
    var findValue = optionValue.find((value) => value.image_name == image_name);
    //  {
    // debugger;
    if (findValue.image_name != "Choose Another") {
      // debugger
      if (showUploadFile) {
        setShowUploadFile(false);
        setShowImage(true);
      }
      setImageSrc(findValue.imgPath);
      setShowImage(true);
      // setValue('avatar_image', data ? data.optionText : '')
      handleAvatarValue(findValue.imgPath);
    } else {
      setShowUploadFile(true);
    }
    // })
  };
  const onChangeHandler = (e: any) => {
    // debugger
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file.name);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
      setShowImage(true);
      handleAvatarValue(file);
    }
  };

  return (
    <div className={styles.root} style={{ margin: "5px" }}>
      <Body1Strong
        style={{ marginTop: "2%", marginBottom: "2%", fontSize: "1.5rem" }}
        id={comboId}
      >
        {headingValue}
      </Body1Strong>
      {type === "avatar" ? (
        <AvatarDialog
          avatars={optionValue}
          selectAvatar={onImageSelect}
          selectedImage={imageSrc}
        />
      ) : (
        // <Combobox
        //   style={{ background: "#e5e5e5", width: "72.5%" }}
        //   aria-labelledby={comboId}
        //   placeholder="Select ....."
        //   onOptionSelect={onSelect}
        // >
        //   {optionValue.map((item) => (
        //     <Option key={item.Id}>{item.image_name}</Option>
        //   ))}
        // </Combobox>
        <BackgroundDialog
          backgrounds={optionValue}
          selectedImage={imageSrc}
          selectBgImage={(img) => {
            // setBgImageSrc(img);
            onImageSelect(img);
          }}
        />
      )}
      {showUploadFile ? (
        <>
          <br></br>
          <br></br>

          <input
            type="file"
            onChange={onChangeHandler}
            accept="image/png, image/gif, image/jpeg"
          />
        </>
      ) : (
        ""
      )}
      {showImg && imageSrc && (
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            width: "65%",
            alignContent: "center",
            padding: "3%",
          }}
        >
          {showImg ? <Image src={imageSrc} height={400} width={400} /> : ""}
        </div>
      )}
    </div>
  );
};

export default DROPDOWNWITHIMAGECONTAINER;
