import * as React from "react";
import {
  makeStyles,
  shorthands,
  LargeTitle
} from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize:"2.5rem",
    justifyContent:"center"

  },
});

interface ValueProps{
    headingValue: string
}

const TEXT : React.FC<ValueProps> = ({headingValue}) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
     
      <LargeTitle>{headingValue}</LargeTitle>
     
    </div>
  );
};

export default TEXT;