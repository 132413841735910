import React from "react";
import {
  FluentProvider,
  Toast,
  webLightTheme,
} from "@fluentui/react-components";
import AvatarGenerator2 from "./Pages/FormWithComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const App: React.FC = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return (
    <FluentProvider theme={webLightTheme}>
      <div style={{ margin: "auto", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AvatarGenerator2
            gpu={params.get("gpu") || ""}
            host={params.get("host") || ""}
            language={params.get("language") || ""}
            owner={params.get("owner") || ""}
            slug={params.get("slug") || ""}
            lessonID={params.get("lessonID") || ""}
            database={params.get("database") || ""}
          />
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </FluentProvider>
  );
};
export default App;
