import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

window.addEventListener(
  "message",
  function (e) {
    if (e?.data?.source === "gan") {
      console.log("acvMessage", e?.data?.message);
      if (e?.data?.message?.accessToken) {        
        this.localStorage.setItem("accessToken", e?.data?.message?.accessToken);
      }
    }
  },
  false
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
