import React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  Button,
  Image,
  DialogTitle,
  DialogContent,
} from "@fluentui/react-components";
import "./AvatarDialog.css";
interface AvatarDialogProps {
  avatars: { Id: number; image_name: string; imgPath: string }[];
  selectAvatar: (url: string) => void;
  selectedImage: string;
}

const AvatarDialog = (props: AvatarDialogProps) => {
  return (
    <Dialog modalType="non-modal">
      <DialogTrigger>
        <Button className="avatar-dialog-button">Select Avatar</Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Choose Avatar</DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                // justifyContent: "center",
                width: "100%",
              }}
            >
              {props.avatars.map((option) => (
                <div
                  key={option.Id}
                  style={{ textAlign: "center", width: "130px" }}
                >
                  {option.image_name == "Choose Another" ? (
                    <DialogTrigger disableButtonEnhancement>
                      <div
                        style={{
                          width: 130,
                          height: 130,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #ccc",
                        }}
                        onClick={() => {
                          props.selectAvatar(option.image_name);
                        }}
                      >
                        {option.image_name}
                      </div>
                    </DialogTrigger>
                  ) : (
                    <>
                      {" "}
                      <DialogTrigger disableButtonEnhancement>
                        <Image
                          src={option.imgPath}
                          alt={option.image_name}
                          className={`avatar-image ${
                            props.selectedImage == option.imgPath
                              ? "selected"
                              : ""
                          }`}
                          width={130}
                          height={130}
                          onClick={() => {
                            props.selectAvatar(option.image_name);
                          }}
                        />
                      </DialogTrigger>
                      <p style={{ margin: 0 }}>{option.image_name}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AvatarDialog;
