import * as React from "react";
import {
  Combobox,
  makeStyles,
  Option,
  shorthands,
  useId,
  Body1Strong
} from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    // maxWidth: "400px",
    width: "100%",
  },
});

interface ValueProps {
  headingValue: string;
  SelectionValue: string;
  optionValue: string[];
  setValue: any;
  name: string;
  value?: string; // Añadimos el valor opcional aquí
}

const COMBOBOX: React.FC<ValueProps> = ({
  headingValue,
  SelectionValue,
  optionValue,
  setValue,
  name,
  value // Añadimos el valor aquí
}) => {
  const comboId = useId("combo-default");
  const styles = useStyles();
  const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    setValue(name, data ? data.optionText : '');
  };

  return (
    <div className={styles.root}>
      <Body1Strong id={comboId} style={{ marginTop: "2%", marginBottom: "2%", fontSize: "1.5rem" }}>
        {headingValue}
      </Body1Strong>
      <Combobox
        style={{ background: "#e5e5e5", width: "72.5%" }}
        aria-labelledby={comboId}
        placeholder={SelectionValue}
        onOptionSelect={onSelect}
        id={name}
        value={value || ''} // Usamos el valor aquí
      >
        {optionValue.map((option) => (
          <Option key={option}>
            {option}
          </Option>
        ))}
      </Combobox>
    </div>
  );
};

export default COMBOBOX;
